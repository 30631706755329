<template>
  <App
    title="勘测单详情"
    left-arrow
    @click-left="onNavBarClickLeft"
  >
    <h1 class="status">{{infoData._status}}</h1>
    <CellGroup title="工单信息">
      <Cell title="工单号">
        <template #default>
          <span>{{infoData._installOrderNo}}</span>
          <Tag
            v-clipboard:copy="infoData._installOrderNo"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >复制</Tag>
        </template>
      </Cell>
      <Cell
        title="创建时间"
        :value="infoData._createTime"
      />
      <Cell title="订单号">
        <template #default>
          <span>{{infoData._orderNo}}</span>
          <Tag
            v-clipboard:copy="infoData._orderNo"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >复制</Tag>
        </template>
      </Cell>
      <Cell
        title="监理意见"
        :value="infoData._overallOpinion"
      />
      <Cell
        title="异常原因"
        :value="infoData._exceptionReason"
      />
      <Cell
        title="异常说明"
        :value="infoData._exceptionExplain"
      />
    </CellGroup>
    <CellGroup title="勘测信息">
      <Cell
        title="签到时间"
        :value="infoData._registerTime"
      />
      <Cell
        title="勘测结论"
        :value="infoData._surveyConclusion"
      />
    </CellGroup>
    <CellGroup title="勘测报告">
      <Cell
        title="物业名称"
        :value="infoData._propertyName"
      />
      <Cell
        title="电源归属主体"
        :value="infoData._powerSupply"
      />
      <Cell
        title="安装方式"
        :value="infoData._installationMode"
      />
      <Cell
        title="固定车位"
        :value="infoData._parkingType"
      />
    </CellGroup>
    <CellGroup title="服务明星">
      <Cell
        title="服务明星"
        :value="infoData._serviceStarName"
      />
      <Cell
        title="工号"
        :value="infoData._serviceStarCode"
      />
      <Cell
        title="联系电话"
        :value="infoData._serviceStarTel"
      />
    </CellGroup>
  </App>
</template>

<script>
import { CellGroup, Cell, Toast, Tag } from 'vant'
import { surveyDetail } from '@/api/order/index'
import { surveyStatusAttr } from './attr.js'
import 'vant/lib/cell-group/style'
import 'vant/lib/cell/style'
import './index.less';

export default {
  name: 'surveyInfo',
  components: { CellGroup, Cell, Tag },
  data () {
    return {
      installOrderNo: 'IO00000961',
      infoData: {}
    }
  },
  methods: {
    onNavBarClickLeft () {
      if (this.$route.query && this.$route.query.source === 'userInfo') {
        this.$router.push({ path: '/customerInfo' })
      } else if (this.$route.query && this.$route.query.source === 'order') {
        this.$router.push({ path: '/orderDetail' })
      } else {
        this.$router.go(-1)
      }
    },
    async getData () {
      const res = await surveyDetail({ 'installOrderNo': this.installOrderNo })
      if (res.success) {
        const detail = res.data.list[0]
        detail.orderSurveyAllFormRespDto = detail.orderSurveyAllFormRespDto || {}
        detail.orderSurveyAllFormRespDto.surveyReportFormRespDto = detail.orderSurveyAllFormRespDto.surveyReportFormRespDto || ''
        detail.orderSurveyAllFormRespDto.surveyReportDetailFormRespDto = detail.orderSurveyAllFormRespDto.surveyReportDetailFormRespDto || {}
        detail.surveyReportDetailFormRespDto = detail.surveyReportDetailFormRespDto || {}
        detail.exceptionFromRespDto = detail.exceptionFromRespDto || {}
        detail.exceptionFromRespDto.ExceptionConfigureRespDto = detail.exceptionFromRespDto.ExceptionConfigureRespDto || {}
        const {
          orderSurveyAllFormRespDto: {
            registerTime,
            surveyReportFormRespDto: { propertyName, powerSupply, installationMode, parkingType, surveyConclusion },
            surveyReportDetailFormRespDto: { supervisionResult, overallOpinion }
          },
          exceptionFromRespDto: { ExceptionConfigureRespDto: { exceptionReason }, exceptionExplain },
          serviceStar
        } = detail
        this.infoData = {
          _installOrderNo: detail.installOrderNo,
          _createTime: detail.createTime,
          _orderNo: detail.orderNo,
          _status: surveyStatusAttr.find(i => i.value === detail.status.toString()).label,
          _supervisionResult: +supervisionResult === 0 ? '拒绝' : +supervisionResult === 1 ? '通过' : '',
          _overallOpinion: overallOpinion,
          _exceptionReason: exceptionReason,
          _exceptionExplain: exceptionExplain,
          _registerTime: registerTime,
          _surveyConclusion: +surveyConclusion === 1 ? '可安装' : +surveyConclusion === 2 ? '不可安装' : '',
          _propertyName: +propertyName === 1 ? '车主点' : +propertyName === 2 ? '物业电' : '',
          _powerSupply: powerSupply,
          _installationMode: +installationMode === 1 ? '落地安装' : +installationMode === 2 ? '挂壁安装' : '',
          _parkingType: +parkingType === 1 ? '自有' : +parkingType === 2 ? '公共' : +parkingType === 3 ? '租赁' : '',
          _serviceStarName: serviceStar.name,
          _serviceStarCode: serviceStar.code,
          _serviceStarTel: serviceStar.tel
        }
      } else {
        Toast(res.msg)
      }
    },
    onCopy () {
      Toast('复制成功')
    },
    onError () {
      Toast('复制失败')
    },
    init () {
      // 获取勘测单号
      this.installOrderNo = this.$route.query.installOrderNo
      this.getData()
    }
  },
  mounted () {
    this.init()
  }
}
</script>
