export const surveyStatusAttr = [
  { value: '1', label: '待勘察' },
  { value: '2', label: '勘察中' },
  { value: '3', label: '勘察待监理' },
  { value: '4', label: '勘察完成' },
  { value: '5', label: '安装准备' },
  { value: '6', label: '待安装' },
  { value: '7', label: '安装中' },
  { value: '8', label: '安装待监理' },
  { value: '9', label: '安装完成' },
  { value: '10', label: '勘察中止' },
  { value: '11', label: '安装中止' }
]
